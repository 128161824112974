import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import useQuery from '../../utils/useQuery'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from '@material-ui/lab/Pagination';

import {BattlePanel, BreadCrumbs, Loader} from '../../components'
import {getBattleListFull, getChamp, getStage, getStageRoleList} from '../../network/fetchApi'
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@material-ui/core';
import {useUpdateEffect} from 'react-use';
import {searchFilters} from "./helpers";

export default function StageScreen() {
    const { root, header, tabs, videoFilterSelect } = useStyles();

    const query = useQuery();
    const history = useHistory();

    const [champData, setChampData] = useState({});
    const [stageData, setStageData] = useState({});
    const [battles, setBattles] = useState({ items: [], hits: 0 })
    const [roles, setRoles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [tabValue, setTabValue] = useState(0);
    const [videoFilter, setVideoFilter] = useState({})
    const [searchFilter, setSearchFilter] = useState([searchFilters.all.value])
    const [participantFilter, setParticipantFilter] = useState({})
    const [page, setPage] = useState(1);
    const ITEMS_ON_PAGE = 10;

    const onItemClick = (data) => {
        history.push(`/battle?id=${data.battle.id}`)
    }

    const onChangeTab = (event, newValue) => {
        setTabValue(newValue);
        if (roles) {
            loadUserBattles(roles[newValue].id)
        }
    };

    const checkRole = (role) => {
        const params = {
            offset: 0,
            limit: 1
        }
        params.filters = [{
            field: 'stage_id',
            equal: query.get("id"),
        }]

        params.filters.push({
            field: 'stage_roles_id',
            equal: role.id,
        })

        getBattleListFull(params, (responseJson) => {
            if (responseJson.status == 200) {
                const { items, hits } = responseJson.body;
                if (hits > 0) {
                    setRoles(prev => {
                        if (prev.length === 0) loadUserBattles(role.id)
                        return prev.concat(role)
                    })
                }
            }
        })
    }

    const loadRoles = (stageID) => {
        getStageRoleList({ id: stageID }, ({ status, body }) => {
            if (status === 200) {
                body.items.forEach((stageRole) => {
                    checkRole(stageRole)
                })
            }
        })
    }

    const loadUserBattles = (stage_roles_id = null) => {
        console.log('load')
        setIsLoading(true)
        const params = {
            offset: (page - 1) * ITEMS_ON_PAGE,
            limit: ITEMS_ON_PAGE,

        }
        params.filters = [{
            field: 'stage_id',
            equal: query.get("id"),
        },
        ]

        if (stage_roles_id) {
            params.filters.push({
                field: 'stage_roles_id',
                equal: stage_roles_id,
            })


        }

        params.order_by = [
            {
                field: 'user_last_name',
                order: 'asc'
            },
            {
                field: 'user_first_name',
                order: 'asc'
            },
            {
                field: 'user_middle_name',
                order: 'asc'
            },
        ]
        getBattleListFull(params, (responseJson) => {
            setIsLoading(false)
            if (responseJson.status == 200) {
                const { items, hits } = responseJson.body;
                setBattles({ items, hits })
            }
        }, searchFilter, stage_roles_id ? participantFilter[stage_roles_id] : '')
    }

    const loadChampByID = (id) => {
        getChamp({ id }, (responseJson) => {
            if (responseJson.status == 200) {
                setChampData(responseJson.body)
            }
        })
    }

    const loadStageByID = (id) => {
        getStage({ id }, (responseJson) => {
            if (responseJson.status == 200) {
                const stage = responseJson.body;
                setStageData(stage)
                loadChampByID(stage.championship_id)
            }
        })
    }

    const filterBattles = (data) => {
        const battles = data.reduce((accumulator, currentValue) => [...accumulator, currentValue.battle], [])

        return battles
    }

    const onParticipantNameChange = (e) => {
        if (roles.length > 0) {
            const currentRole = roles[tabValue].id

            setParticipantFilter({
                [currentRole]: e.target.value
            })
        }
    }

    const onPageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        const stageID = query.get("id");
        loadStageByID(stageID)
        loadRoles(stageID)
    }, [])

    useEffect(() => {
        if (roles.length > 0) loadUserBattles()
    }, [page])

    useUpdateEffect(() => {
        if (roles.length > 0) loadUserBattles(roles[tabValue].id)
    }, [searchFilter])
    
    const handleUpdateFilter = () => {
        if (roles.length > 0) loadUserBattles(roles[tabValue].id)
    }
    
    const checkIsRoleNotPlayer = (newTabValue) => {
        return roles.length > 0 && roles[newTabValue ?? tabValue].code_name !== 'player'
    }

    const searchFiltersOnChange = (event) => {
        const {
            target: { value },
        } = event;

        const filters = (typeof value === 'string' ? value.split(',') : value).map(
            filter => searchFilters[filter]
        )
        
        if (filters.some(filter => filter.value === searchFilters.all.value)) {
            const existingFiltersIds = searchFilter.map(filter => searchFilters[filter].id)
            if (existingFiltersIds.includes(searchFilters.all.id)) {
                setSearchFilter(
                    filters.filter(filter => filter.id !== searchFilters.all.id).map(filter => filter.value)
                )  
            }
            else {
                setSearchFilter([searchFilters.all.value])
            }
        }
        else {
            filters.reverse()
            
            let i = 0;
            while (i < filters.length) {
                filters[i].exclude.forEach(
                    id => {
                        const index = filters.findIndex(filter => filter.id === id) 
                        if (index >= 0) {
                            filters.splice(index, 1)
                        }
                    }
                )
                
                i++;
            }
            
            if (filters.length > 0) {
                setSearchFilter(filters.map(filter => filter.value))   
            } else {
                setSearchFilter([searchFilters.all.value])
            }
        }
    }
    
    return (
        <div className={root}>
            <Grid container direction='column' style={{ padding: 20 }}>
                <Grid item xs>
                    <BreadCrumbs data={[{ name: 'Компании', href: '/profile' }, { name: "Чемпионаты", href: champData.company_id ? `/company?id=${champData.company_id}` : null }, { name: "Этапы", href: stageData.championship_id ? `/champ?id=${stageData.championship_id}` : null }, { name: "Упражнения" }]} />
                </Grid>
                <Grid item xs>
                    <Tabs scrollButtons="auto" variant="scrollable" className={tabs} value={tabValue} onChange={(onChangeTab)}>
                        {roles && roles.map((role, index) => (
                            <Tab data-role={role} label={role.title} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
                        ))}
                    </Tabs>
                </Grid>

                <Grid item xs direction='column' justify='center'>
                    {
                        checkIsRoleNotPlayer()
                        &&
                        <Grid items style={{
                            marginBottom: '1rem',
                            display: 'flex'
                        }}>
                            <TextField
                                label="Поиск по ФИО участника"
                                value={roles.length > 0 ? participantFilter[roles[tabValue].id] ?? '' : ''}
                                onChange={onParticipantNameChange}
                                variant="outlined"
                                // onBlur={handleUpdateFilter}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        handleUpdateFilter()
                                    }
                                }}
                                style={{
                                    flex: 1,
                                    marginRight: '0.5rem'
                                }}
                            />
                            <FormControl variant="outlined" className={videoFilterSelect}>
                                <InputLabel id="demo-simple-select-outlined-label">Фильтр</InputLabel>
                                <Select
                                    labelId="video-filter-select"
                                    id="video-filter-select"
                                    value={searchFilter}
                                    label="Фильтр"
                                    multiple
                                    input={<OutlinedInput label="Фильтр" />}
                                    renderValue={(selected) => selected.map(value => searchFilters[value].text).join(', ')}
                                    onChange={searchFiltersOnChange}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {Object.values(searchFilters).sort((a, b) => a.id - b.id).map(
                                        ({id, value, text}) => (
                                            <MenuItem key={id} value={value}>
                                                <ListItemText primary={text} />
                                                <Checkbox checked={searchFilter.includes(value)} color={"primary"}/>
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {battles.items.map((item, index) => (
                        <BattlePanel data={item} onClick={onItemClick} />
                    ))}
                    {!isLoading && battles.items.length == 0 &&
                        <Typography variant="h6" className={header}>
                            Вы не участвуете ни в одном этапе
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Pagination count={Math.ceil(battles.hits / ITEMS_ON_PAGE)} page={page} onChange={onPageChange} color="primary" />
                </Grid>
                {isLoading && <Loader isLoading={isLoading} />}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {

    },
    header: {
        width: '100%',
        textAlign: 'left',
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
    videoFilterSelect: {
        width: '15rem'
    }
}));
