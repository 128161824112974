import { fetchApi } from './fetchApi';
import { APIHOST } from './url';

class BaseAPI {
  static defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  static APIHOST = APIHOST;

  static call(
    path,
    { method = 'GET', headers, body = null, rewriteHeaders = false } = {
      method: 'GET',
      headers: BaseAPI.defaultHeaders,
      rewriteHeaders: false,
      body: null,
    },
  ) {
    return new Promise((resolve, reject) => {
      fetchApi(
        `${APIHOST}${path}`,
        method,
        { ...headers, ...(rewriteHeaders ? {} : this.defaultHeaders) },
        body,
        resolve,
        reject,
      );
    });
  }
}

export default BaseAPI;
