import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const InfoVideoForm = (props) => {
  const { text } = props;

  return (
    <Paper elevation={3} style={{ padding: 20, display: 'flex', height: 500 }}>
      <div
        style={{
          padding: 30,
          flexGrow: 1,
          border: '2px dashed gray',
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          style={{ textAlign: 'center', marginBottom: 10 }}
        >
          {text}
        </Typography>
      </div>
    </Paper>
  );
};

export default InfoVideoForm;