import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SuccessMark from './SuccessMark';

export default function BattlePanel(props) {
  const { data, onClick } = props;

  const { root, header, helpText } = useStyles();
  const { info } = useSelector((state) => state.user);

  const getRolesName = (data) => {
    let result = [];
    data.battle.roles.forEach((role) => {
      if (role.user_info.findIndex((user) => user.id === info.id) !== -1)
        result.push(role.title);
    });

    return result.join(', ');
  };

  const getFullName = (data) => {
    return `${data.last_name} ${data.first_name} ${data.middle_name}`;
  };

  return (
    <Paper onClick={() => onClick(data)} elevation={3} className={root}>
      <Typography
        variant="h4"
        align="left"
        style={{ width: '100%', marginBottom: 20 }}
      >
        Упражнение
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          gap: 20,
        }}
      >
        {data.video && <SuccessMark text={'Добавлено видео'} />}
        {data.battle_grades && !!data.battle_grades.length && (
          <SuccessMark text={'Оценки внесены'} />
        )}
        {!!data?.video?.comments?.items.some(
          (comment) => comment.user_id === info.id,
        ) && <SuccessMark text={'Комментарии внесены'} />}
      </div>

      <TextField
        id="standard-read-only-input"
        label="Ваша роль"
        value={getRolesName(data)}
        variant="outlined"
        className={header}
        multiline
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="standard-read-only-input"
        label="ФИО Участника"
        value={data.player ? getFullName(data.player) : ''}
        variant="outlined"
        className={header}
        multiline
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="standard-read-only-input"
        label="Название кейса"
        value={data.case ? data.case.title : ''}
        variant="outlined"
        className={header}
        multiline
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <span className={helpText}>Нажмите, чтобы посмотреть подробнее</span>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    paddingBottom: 15,
    marginBottom: 30,
    cursor: 'pointer',
  },
  header: {
    width: '100%',
    pointerEvents: 'none',
    marginBottom: 20,
  },
  helpText: {
    fontSize: 12,
    opacity: 0.5,
  },
}));
