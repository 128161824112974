import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

const UploadVideoForm = (props) => {
  const { fileInputRef, onChange } = props;

  return (
    <Paper elevation={3} style={{ padding: 20, display: 'flex', height: 500 }}>
      <div
        style={{
          flexGrow: 1,
          border: '2px dashed gray',
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          style={{ textAlign: 'center', marginBottom: 10 }}
        >
          Загрузите записанное видео выполненного упражнения в течение 24 часов
          после выполнения
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
          startIcon={<GetAppIcon />}
        >
          Загрузить видео
        </Button>
        <input
          accept="video/*"
          ref={fileInputRef}
          type="file"
          hidden
          onChange={onChange}
        />
      </div>
    </Paper>
  );
};

export default UploadVideoForm;
