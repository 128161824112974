import {USERSIGNIN, USERLOGOUT, USERGET, GETBATTLE, VIMEOCREATE,
    BATTLELIST, GETFILE, CONFIRMUSER, GETCOMMENT, GETCHAMP, GETSTAGE,
    COMPANYLIST, CHAMPLIST, STAGELIST, ROLES} from './url'
import * as tus from "tus-js-client";


export const fetchApi = (url, method, headers, body, response) => {
    var getRequestInfo = {
        method: method,
        headers: headers,
        credentials: 'include',
    }

    var postRequestInfo = {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'include'
    }

    fetch(url, method == 'POST' || body != null?postRequestInfo:getRequestInfo)
    .then((response) =>
    {
        return Promise.all([response.status, response.json()])
    })
    .then((res) => ({status: res[0], body: res[1]}))
    .then((responseJson) => {
        response(responseJson);
    })
    .catch((error) => {
        console.error("FetchApi Error("+ url + "): " + error);
    });
}

//// API v1
export const getCompanyList = (params, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    let filter = "";
    if(params) filter = "?filter=" + JSON.stringify(params)

    fetchApi(COMPANYLIST + filter, 'GET', headers, null, response)
}

export const getChampList = (params, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    let filter = "";
    if(params) filter = "?filter=" + JSON.stringify(params)

    fetchApi(CHAMPLIST + filter, 'GET', headers, null, response)
}

export const getStageList = (params, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    let filter = "";
    if(params) filter = "?filter=" + JSON.stringify(params)

    fetchApi(STAGELIST + filter, 'GET', headers, null, response)
}

export const userSignIn = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    fetchApi(USERSIGNIN, 'POST', headers, body, response)
}

export const userLogOut = (response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    fetchApi(USERLOGOUT, 'POST', headers, null, response)
}

export const confirmUser = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    fetchApi(CONFIRMUSER, 'POST', headers, body, response)
}

export const getUserInfo = (response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    fetchApi(USERGET, 'GET', headers, null, response)
}

export const getUserInfoByID = (body,response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    fetchApi(`${USERGET}/${body.id}`, 'GET', headers, null, response)
}

export const getChamp = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    fetchApi(`${GETCHAMP}/${body.id}`, 'GET', headers, null, response)
}

export const getStage = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    fetchApi(`${GETSTAGE}/${body.id}`, 'GET', headers, null, response)
}

export const getStageRoleList = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    fetchApi(`${GETSTAGE}/${body.id}${ROLES}`, 'GET', headers, null, response)
}

export const getBattleListFull = (params,  response,  filters, fioFilter) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    let filter = "";
    if(params) filter = "?filter=" + JSON.stringify(params)

    if (filters?.length > 0) {
        filter += params ? "&" : "?"
        filter += `filters=` + filters.join()
    }
    
    if (fioFilter) {
        if (filter.includes('?')) {
            filter+='&fio=' + fioFilter
        } else {
            filter+='?fio=' + fioFilter
        }
    }

    fetchApi(`${BATTLELIST}/full` + filter, 'GET', headers, null, response)
}

export const updateComment = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    fetchApi(`${GETCOMMENT}/${body.id}`, 'POST', headers, body, response)
}

export const deleteComment = (body, response) => {
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }

    fetchApi(`${GETCOMMENT}/${body.id}`, 'DELETE', headers, body, response)
}

export const createVimeoVideo = (file, response, onProgress) => {
    // const fileNameParts = file.name.split('.')
    // const vimeoFileName = file.name.length > 128 ?  file.name.slice(0,120) + '...' + fileNameParts[fileNameParts.length-1] : file.name

    const body = {
        name: file.name,
        upload: {
            approach: "tus",
            size: file.size
        },
        privacy: {
            comments: "nobody",
            view: "unlisted",
            download: false
        },
        embed: {
            buttons: {
                embed: false,
                like: false,
                share: false,
                watchlater: false
            },
            title: {
                name: 'hide',
                owner: 'hide',
                portrait: 'hide',
            },
            logos: {
                vimeo: false
            },
            speed: true
        }
    }

    fetch(VIMEOCREATE, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.vimeo.*+json;version=3.4'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(body)
    }).then((response) =>
    {
        return Promise.all([response.status, response.json()])
    })
    .then((res) => ({status: res[0], body: res[1]}))
    .then((responseJson) => {
        console.log("\n" + 'POST' + "(" + VIMEOCREATE + "):");
        console.log(" - body: " + JSON.stringify(body));
        console.log(" - responseJson: " + JSON.stringify(responseJson));
        var upload = new tus.Upload(file, {
            uploadUrl: responseJson.body.upload.upload_link,
            onError: function(error) {
                console.log("Failed because: " + error)
            },
            onSuccess: function() {
                response(responseJson);
                console.log("Download %s from %s", upload.file.name, upload.url)
            },
            onProgress: function(bytesUploaded, bytesTotal) {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                if(onProgress) onProgress(percentage)
                console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
        })
        upload.start()
    })
    .catch((error) => {
        console.error("Error("+ VIMEOCREATE + "): " + error);
    });
}

export const getFile = (url) => {
    return GETFILE + url
}
