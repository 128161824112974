import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React from "react";

export default function SuccessMark(props) {
    return (
        <span
            style={{
                width: 'auto',
                textAlign: 'left',
                marginBottom: 20,
                fontSize: 15,
                color: 'green',
                display: 'flex',
                alignItems: 'center',
            }}
        >
        <CheckCircleOutlineIcon />
        <span style={{ marginLeft: 5 }}>{props.text}</span>
      </span>
    );
};