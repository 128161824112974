import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { interpolateColors } from '../../../utils/colorInterpolation';

const GradesViewOnlyModal = ({
  open,
  onClose,
  gradesRange = [],
  gradesInfo = [],
  minColor = { red: 220, green: 122, blue: 109 },
  maxColor = { red: 125, green: 214, blue: 149 },
}) => {
  const styles = useStyles();

  const getColorForGrade = useMemo(() => {
    const minValue = gradesRange.min_grade;
    const maxValue = gradesRange.max_grade;
    return interpolateColors({
      currentRatings: gradesInfo.flatMap((criterion) =>
        criterion.CriterionGradesByRole.map((result) => parseInt(result.Grade)),
      ),
      minColor,
      maxColor,
      minValue,
      maxValue,
    });
  }, [gradesInfo, gradesRange, minColor, maxColor]);

  const regex = /\d+./;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      minHeight="md"
      onClose={onClose}
    >
      <DialogContent className={styles.mainContainer}>
        {gradesInfo.map((criterion, index) => {
          const accumulatedIndex = gradesInfo
            .slice(0, index)
            .reduce((acc, cur) => acc + cur.CriterionGradesByRole.length, 0);

          return (
            <div className={styles.criterionContainer} key={index}>
              <div className={styles.title}>
                <h1>
                  {index + 1}. {criterion?.Name}
                </h1>
              </div>
              <div className={styles.gradeList}>
                {criterion?.CriterionGradesByRole?.map((result, indx) => {
                  const colorIndex = accumulatedIndex + indx;
                  return (
                    <div className={styles.gradeBlock} key={indx}>
                      <div> {result?.Role}</div>
                      <div
                        className={styles.gradeCircle}
                        style={{
                          backgroundColor: getColorForGrade[colorIndex]?.color,
                        }}
                      >
                        {result?.Grade}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={styles.descriptionContainer}>
                {criterion?.Description?.map(
                  (description, index) =>
                    description.length !== 0 && (
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: description.replace(
                            regex,
                            `<span style="font-weight: bold; color: #121212;">$&</span>`,
                          ),
                        }}
                      />
                    ),
                )}
              </div>
            </div>
          );
        })}
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button className={styles.closeButton} onClick={onClose}>
            Закрыть
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    backgroundColor: '#F5F5F5',
    paddingBottom: 30,
  },
  criterionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'start',
    backgroundColor: '#FFFFFF',
    width: '100%',
    borderRadius: 8,
    padding: 10,
  },
  gradeList: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
  gradeBlock: {
    lineHeight: '32px',
    verticalAlign: 'middle',
    display: 'flex',
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    fontSize: 18,
    fontWeight: 500,
    gap: 5,
  },
  gradeCircle: {
    backgroundColor: '#dc7a6d',
    borderRadius: '100%',
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto, sans-serif',
    width: 32,
    height: 32,
    lineHeight: '32px',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    fontSize: 16,
    padding: 10,

    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    color: '#888888',
  },
  closeButton: {
    position: 'absolute',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 700,
    padding: 10,
    width: '95%',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
}));

export default GradesViewOnlyModal;
