import { useCallback, useState } from 'react';

export const useSwitch = (initialState = false) => {
  const [active, setActiveState] = useState(initialState);

  const turnOn = useCallback(() => setActiveState(true), []);

  const turnOff = useCallback(() => setActiveState(false), []);

  return [active, turnOn, turnOff];
};
