import React from 'react';
import Typography from "@material-ui/core/Typography";

const StageDatesSign = (props) => {
  const { stage } = props;

  return (
    <div style={{ marginTop: 20 }}>
      <Typography
        variant="h6"
        style={{ textAlign: 'center', whiteSpace: 'break-spaces' }}
      >{`Период проведения ${stage.title}:\n${new Date(
        stage.time_start,
      ).toLocaleDateString()} - ${new Date(
        stage.time_finish,
      ).toLocaleDateString()}`}</Typography>
    </div>
  );
};

export default StageDatesSign;
