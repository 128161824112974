import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import useQuery from '../../utils/useQuery';
import RoleContainer from './RoleContainer';

import { BreadCrumbs, Loader, PdfViewerPanel } from '../../components';
import BattleAPI from '../../network/BattleAPI';
import { createVimeoVideo, getFile } from '../../network/fetchApi';
import { useSwitch } from '../../utils/hooks/useSwitch';
import CheckVideoForm from './CheckVideoForm';
import CommentVideoForm from './CommentVideoForm';
import ExerciseAssessment from './ExerciseAssessment';
import AssessmentSuccessModal from './ExerciseAssessment/AssessmentSuccessModal';
import GradesPanel from './GradesPanel';
import GradesViewOnlyModal from './GradesViewOnlyModal';
import InfoVideoForm from './InfoVideoForm';
import StageDatesSign from './StageDatesSign';
import UploadVideoForm from './UploadVideoForm';
import { useStyles } from './styles';
export default function BattleScreen() {
  const { root, header, fab1, fab2, tabs } = useStyles();
  const fileInputRef = useRef();
  const query = useQuery();

  const { info } = useSelector((state) => state.user);

  const [battleData, setBattleData] = useState(null);
  const [userRole, setUserRole] = useState({});
  const [file, setUploadFile] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isRatingInProgress, setRatingInProgress] = useState(false);
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
  const [tabValue, setTabValue] = useState(0);

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useSwitch();
  const [
    gradesViewOnlyModalOpen,
    openGradesViewOnlyModal,
    closeGradesViewOnlyModal,
  ] = useSwitch();

  const loadBattleByID = async (id, params = {}) => {
    try {
      const response = await BattleAPI.getBattleFull(id, params);
      if (response.status === 200) {
        setBattleData(response.body);
        setUserRole(getRoleFromBattleData(response.body));
      }
    } catch (error) {}
  };

  const onUploadNewVideo = () => {
    if (file != null) {
      setIsVideoLoading(true);
      createVimeoVideo(
        file,
        async (responseJson) => {
          if (responseJson.status == 200) {
            setIsVideoLoading(true);
            try {
              const response = await BattleAPI.addBattleVideo({
                id: battleData.battle.id,
                link: responseJson.body.link,
              });
              if (response.status === 200)
                setBattleData({ ...battleData, video: response.body });
            } catch (error) {
            } finally {
              setIsVideoLoading(false);
              setUploadVideoProgress(0);
            }
          }
        },
        (value) => {
          setIsVideoLoading(true);
          setUploadVideoProgress(value);
        },
      );
    }
  };

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onRateByCriterion = useCallback(
    async (battleId, criterionId, grade) => {
      try {
        setRatingInProgress(true);
        const response = await BattleAPI.rateBattleByCriterion(battleId, {
          criterion_id: criterionId,
          grade: grade,
        });
        if (response.status === 200)
          setBattleData((battleData) => {
            if (!battleData) return battleData;

            if (!battleData.battle_grades)
              return {
                ...battleData,
                battle_grades: [response.body],
              };

            const battleGradeInDataIndex = battleData.battle_grades.findIndex(
              (battleGrade) => battleGrade.id === response.body.id,
            );

            if (battleGradeInDataIndex === -1)
              return {
                ...battleData,
                battle_grades: [...battleData.battle_grades, response.body],
              };

            return {
              ...battleData,
              battle_grades: [
                ...battleData.battle_grades.slice(0, battleGradeInDataIndex),
                response.body,
                ...battleData.battle_grades.slice(battleGradeInDataIndex + 1),
              ],
            };
          });
        openSuccessModal();
      } catch (error) {
      } finally {
        setRatingInProgress(false);
      }
    },
    [openSuccessModal],
  );

  const onClearVideoFile = () => {
    setUploadFile(null);
  };

  const onVideoFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const getRoleFromBattleData = (data) => {
    const isPlayer = data.player != null && data.player.id == info.id;
    const isClient = data.client != null && data.client.id == info.id;
    const isExpertIn =
      data.experts_internal != null &&
      data.experts_internal.some((expert) => expert.id == info.id);
    const isExpertEx =
      data.experts_external != null &&
      data.experts_external.some((expert) => expert.id == info.id);

    return { isPlayer, isClient, isExpertIn, isExpertEx };
  };

  const getRights = () => {
    const battleRights = battleData.battle.rights;
    const rolesRights = battleData.battle.roles;

    return rolesRights
      .filter(
        (role) =>
          role.user_info.findIndex((user) => user.id === info.id) !== -1,
      )
      .map((role) => ({
        write:
          battleRights.write != null ? battleRights.write : role.rights.write,
        comments:
          battleRights.comments != null
            ? battleRights.comments
            : role.rights.comments,
        upload:
          battleRights.upload != null
            ? battleRights.upload
            : role.rights.upload,
        watch:
          battleRights.watch != null ? battleRights.watch : role.rights.watch,
      }))
      .reduce((acc, item) => {
        for (let i in item) {
          if (i != 'comments') {
            if (item[i]) acc[i] = true;
            else if (!Boolean(acc[i])) acc[i] = false;
          }
        }
        if (item.comments == 'all') acc.comments = 'all';
        else if (item.comments == 'self' && acc.comments !== 'all')
          acc.comments = 'self';
        else if (!Boolean(acc.comments)) acc.comments = 'none';

        return acc;
      }, {});
  };

  const getVideoBlockByRole = () => {
    const userRights = getRights();

    if (battleData.video == null) {
      if (userRights.upload) {
        if (file == null) {
          return (
            <UploadVideoForm
              fileInputRef={fileInputRef}
              onChange={onVideoFileChange}
            />
          );
        } else {
          return (
            <CheckVideoForm
              isLoading={isVideoLoading}
              uploadVideoProgress={uploadVideoProgress}
              url={URL.createObjectURL(file)}
              onCancel={onClearVideoFile}
              onAccept={onUploadNewVideo}
            />
          );
        }
      } else {
        return (
          <InfoVideoForm
            text={
              'Видео для комментирования станет доступно после того, как Участник его загрузит'
            }
          />
        );
      }
    } else {
      return (
        <CommentVideoForm
          battleData={battleData}
          setBattleData={setBattleData}
          rights={userRights}
        />
      );
    }
  };

  const isExerciseAssessmentVisible = () => {
    const exerciseRightsCheck = battleData.battle.rights.give_grades;

    if (exerciseRightsCheck === null) {
      const rolesRightsCheck = battleData.battle.roles.some(
        (role) =>
          role?.user_info?.some((expert) => expert.id === info.id) &&
          !!role?.rights.give_grades,
      );
      return rolesRightsCheck;
    }

    return exerciseRightsCheck;
  };

  const getSeeGradesRight = () => {
    if (!battleData) return null;

    const seeGrades = battleData.battle.rights.see_grades;

    if (!seeGrades) {
      const weight = {
        yes: 3,
        self: 2,
        no: 1,
      };

      const seeGradesRights = battleData.battle.roles
        .filter((role) =>
          role?.user_info?.some((expert) => expert.id === info.id),
        )
        .map((role) => role?.rights?.see_grades?.toLowerCase());

      const maxWeight = seeGradesRights.reduce(
        (max, curr) => Math.max(max, weight[curr] || 0),
        0,
      );

      const seeGradesRolesRight = Object.keys(weight).find(
        (key) => weight[key] === maxWeight,
      );

      return seeGradesRolesRight;
    }

    return seeGrades;
  };

  useEffect(() => {
    const battleID = query.get('id');
    const params = {
      order_by: [
        {
          field: 'time',
          order: 'asc',
        },
      ],
      limit: 100,
    };
    loadBattleByID(battleID, params);
  }, []);

  const battleGradesByCriterion = useMemo(
    () =>
      battleData?.average_grades_by_criteria?.map(
        ({ criterion_name, average_grade }) => ({
          criterion_name,
          average_grade,
        }),
      ) ?? [],
    [battleData],
  );

  if (battleData == null)
    return (
      <div className={root}>
        <Loader isLoading={true} />
      </div>
    );

  return (
    <div className={root}>
      <div style={{ padding: 20 }}>
        <BreadCrumbs
          data={[
            { name: 'Компании', href: '/profile' },
            {
              name: 'Чемпионаты',
              href: battleData.company.id
                ? `/company?id=${battleData.company.id}`
                : null,
            },
            {
              name: 'Этапы',
              href: battleData.championship.id
                ? `/champ?id=${battleData.championship.id}`
                : null,
            },
            {
              name: 'Упражнения',
              href: battleData.stage.id
                ? `/stage?id=${battleData.stage.id}`
                : null,
            },
            { name: battleData.case ? battleData.case.title : 'Упражнение' },
          ]}
        />
      </div>
      <div style={{ whiteSpace: 'nowrap' }}>
        <Grid
          container
          alignItems="center"
          style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}
          >
            <img
              src={
                battleData.company ? getFile(battleData.company.icon_link) : ''
              }
              style={{
                width: 'auto',
                height: 'auto',
                maxHeight: 100,
                maxWidth: 400,
              }}
              alt="Remy Sharp"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className={header}>
              {`${battleData.case ? battleData.case.title : ''}`}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container style={{ paddingLeft: 20, paddingRight: 20 }} spacing={3}>
        <Grid item xs={12}>
          {getVideoBlockByRole()}
        </Grid>
        <Grid item xs={12} md={4}>
          {(getSeeGradesRight() === 'yes' ||
            getSeeGradesRight() === 'self') && (
            <>
              <GradesPanel
                battleGradesByCriterion={battleGradesByCriterion}
                openGradesViewOnlyModal={openGradesViewOnlyModal}
              />
            </>
          )}
          <Paper elevation={3} style={{ padding: 20 }}>
            {battleData.player && (
              <RoleContainer
                title={'Роль "Участник"'}
                data={[battleData.player]}
              />
            )}
            {battleData.client && (
              <RoleContainer
                title={'Роль "Клиент"'}
                data={[battleData.client]}
              />
            )}
            {battleData.experts_internal &&
              battleData.experts_internal.length > 0 && (
                <RoleContainer
                  title={'Внутренний эксперт'}
                  data={battleData.experts_internal}
                />
              )}
            {battleData.experts_external &&
              battleData.experts_external.length > 0 && (
                <RoleContainer
                  title={'Внешний эксперт'}
                  data={battleData.experts_external}
                />
              )}
            {battleData.battle.roles
              .filter((role) => role.code_name === '')
              .map((role) => (
                <RoleContainer
                  key={role.id}
                  title={role.title}
                  data={role.user_info}
                />
              ))}
            <StageDatesSign stage={battleData.stage} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          {isExerciseAssessmentVisible() && (
            <ExerciseAssessment
              criteria={battleData.criteria}
              battleGrades={battleData.battle_grades}
              battleId={battleData.battle.id}
              onRateByCriterion={onRateByCriterion}
              isRatingInProgress={isRatingInProgress}
            />
          )}
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            className={tabs}
            value={tabValue}
            onChange={onChangeTab}
          >
            <Tab label="Общее условие" id="tab-0" aria-controls="tabpanel-0" />
            <Tab
              style={{
                display:
                  userRole.isPlayer || !userRole.isClient ? 'initial' : 'none',
              }}
              label='Условие для роли "Участник"'
              id="tab-1"
              aria-controls="tabpanel-1"
            />
            <Tab
              style={{
                display:
                  userRole.isClient || !userRole.isPlayer ? 'initial' : 'none',
              }}
              label='Условие для роли "Клиент"'
              id="tab-2"
              aria-controls="tabpanel-2"
            />
          </Tabs>

          <PdfViewerPanel
            file={getFile(
              tabValue === 0
                ? battleData.case?.pdf_link_general
                : tabValue === 1
                ? battleData.case?.pdf_link_player
                : tabValue === 2
                ? battleData.case?.pdf_link_client
                : null,
            )}
          />
        </Grid>
      </Grid>
      <Fab
        onClick={() => {
          window.open('https://wa.me/79055551907/');
        }}
        className={fab2}
        color="primary"
        aria-label="add"
      >
        <WhatsAppIcon fontSize="large" />
      </Fab>
      <Fab
        onClick={() => {
          window.open('mailto:chemp@emtway.ru');
        }}
        className={fab1}
        color="primary"
        aria-label="add"
      >
        <EmailIcon fontSize="large" />
      </Fab>
      <AssessmentSuccessModal
        open={successModalOpen}
        onClose={closeSuccessModal}
      />
      <GradesViewOnlyModal
        open={gradesViewOnlyModalOpen}
        onClose={closeGradesViewOnlyModal}
        gradesInfo={battleData?.detailed_grade_info}
        gradesRange={battleData?.min_max_grade_for_stage}
      />
    </div>
  );
}
