import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import useQuery from '../utils/useQuery'

import {BreadCrumbs, ChampPanel, Loader} from '../components'
import {getChampList} from '../network/fetchApi'

export default function CompanyScreen() {
    const {root, header, tabs} = useStyles();
    const query = useQuery();
    const history = useHistory();

    const [champs, setChamps] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const onItemClick = (data) => {
        history.push(`/champ?id=${data.id}`)
    }

    const loadUserChamps = (offset = 0) => {
        setIsLoading(true)
        getChampList({offset, limit: 100, filters: [
            {
                field: 'company_id',
                equal: query.get("id")
            }
        ]}, (responseJson) => {
            setIsLoading(false)
            if(responseJson.status == 200){
                setChamps(responseJson.body.items)
            }
        })
    }

    const filterChamps = (initial, data) => {
        const companyID = query.get("id");

        const champs = data.reduce((accumulator, currentValue) => 
        accumulator.some(item => item.id === currentValue.championship.id)?accumulator:[...accumulator, currentValue.championship], initial)
        .filter(item => item.company_id  === companyID);

        console.log("champs: "+JSON.stringify(champs))

        return champs
    }

    useEffect(() => {
        loadUserChamps()
    }, [])

    return (
        <div className={root}>
            <Grid container direction='column' style={{padding: 20}}>
                <Grid item xs>
                    <BreadCrumbs data={[{name: "Компании", href: '/profile'}, {name: "Чемпионаты"}]}/>
                </Grid>
                {!isLoading && <Grid item xs direction='column'>
                        {champs.map((item) => (
                            <Grid item xs={12}>
                                <ChampPanel data={item} onClick={onItemClick}/>
                            </Grid>
                        ))}
                        {champs.length == 0 && 
                            <Typography variant="h6" className={header}>
                                Вы не участвуете ни в одном чемпионате
                            </Typography>
                        }
                </Grid>}
                {isLoading && <Loader isLoading={isLoading}/>}
             </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        width: '100%'
    },
    header: {
        width: '100%',
        textAlign: 'left',
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
}));
