import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {getFile} from '../network/fetchApi'

export default function CompanyPanel(props) {
    const {data, onClick} = props;
    const {name, icon_link, } = data;
    const {root, avatar, header, helpText} = useStyles();

    return (
        <Paper elevation={3} className={root} onClick={() => onClick(data)}>
            <Typography variant="h4" align='left' style={{width: '100%' , marginBottom: 20}}>Компания</Typography>
            <img alt={name} src={getFile(icon_link)} className={avatar} />
            <TextField
                id="standard-read-only-input"
                label="Название"
                value={name}
                variant="outlined"
                className={header}
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <span className={helpText}>Нажмите, чтобы открыть чемпионаты</span>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        padding: 30,
        paddingBottom: 15,
        marginBottom: 30,
        cursor: 'pointer'
    },
    avatar: {
        width: 'auto', 
        height: 'auto', 
        maxHeight: 150,
        maxWidth: '100%',
    },
    header: {
        width: '100%',
        marginTop: 30,
        pointerEvents: 'none'
    },
    helpText: {
        marginTop: 15,
        fontSize: 12,
        opacity: 0.5,
    }
}));
