import React, { useState, useMemo, useCallback, useEffect } from 'react';

import ExerciseGrades from '../ExerciseGrades';
import ExerciseCriterionText from '../ExerciseCriterionText';
import ExpandablePanel from '../ExpandablePanel';

const ExerciseCriterion = ({
  criterion,
  battleId,
  battleGrade,
  openedExpandableId,
  toggleExpandable,
  onRateByCriterion,
}) => {
  const [selectedGrade, setSelectedGrade] = useState(
    battleGrade ? battleGrade.grade : -1,
  );
  const [selectedNowGrade, setSelectedNowGrade] = useState(selectedGrade)
  const [isGradeGiven, setIsGradeGiven] = useState(!!battleGrade)

  const isOpen = openedExpandableId === criterion.id;

  const rateByCriterion = useCallback(async () => {
    const gradeData = criterion.grades.find(
      (gradeData) => gradeData.grade === selectedNowGrade,
    );
    if (!gradeData) return;
    setSelectedGrade(selectedNowGrade)
    onRateByCriterion(battleId, criterion.id, gradeData.grade);
    setIsGradeGiven(true)
  }, [
    criterion.grades,
    criterion.id,
    onRateByCriterion,
    battleId,
    selectedGrade,
    selectedNowGrade
  ]);

  const subheader = useMemo(
    () => (
      <ExerciseGrades
        gradesData={criterion.grades}
        selectedGrade={selectedGrade}
        selectedNowGrade={selectedNowGrade}
        setSelectedNowGrade={setSelectedNowGrade}
      />
    ),
    [criterion.grades, selectedGrade, selectedNowGrade],
  );

  const content = useMemo(() => {
    const gradeData = criterion.grades.find(
      (gradeData) => gradeData.grade === selectedNowGrade,
    );
    if (!gradeData)
      return ({ isNotRoundedContentRight, isNotRoundedContentLeft }) => (
        <ExerciseCriterionText
          description="Выберите оценку чтобы увидеть её описание"
          isNotRoundedContentRight={isNotRoundedContentRight}
          isNotRoundedContentLeft={isNotRoundedContentLeft}
        />
      );
    return ({ isNotRoundedContentRight, isNotRoundedContentLeft }) => (
      <ExerciseCriterionText
        description={gradeData.description}
        onClick={rateByCriterion}
        isNotRoundedContentRight={isNotRoundedContentRight}
        isNotRoundedContentLeft={isNotRoundedContentLeft}
      />
    );
  }, [criterion.grades, rateByCriterion, selectedGrade, selectedNowGrade]);

  const handleCriterionClose = useCallback(() => {
    setSelectedGrade(battleGrade ? battleGrade.grade : -1);
  }, [battleGrade]);

  useEffect(() => {
    setSelectedGrade(battleGrade ? battleGrade.grade : -1);
  }, [battleGrade]);

  return (
    <ExpandablePanel
      key={criterion.id}
      id={criterion.id}
      open={isOpen}
      toggle={toggleExpandable}
      header={criterion.name}
      Subheader={subheader}
      Content={content}
      onClose={handleCriterionClose}
      isGradeGiven={isGradeGiven}
    />
  );
};

export default ExerciseCriterion;
