import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import RolePanel from "../RolePanel";

const RoleContainer = (props) => {
  const { title, data } = props;

  const { info } = useSelector((state) => state.user);
  const isSelf = data !== null && data.some((expert) => expert.id === info.id);

  return (
    <div style={{ marginTop: 20 }}>
      <Typography
        variant="h5"
        style={{
          textAlign: 'left',
          marginBottom: 10,
          color: isSelf ? 'green' : 'black',
        }}
      >
        {title}
        <span>{isSelf ? ' (Ваша роль)' : ''}</span>:
      </Typography>
      {data &&
        data.map((user) => (
          <RolePanel
            key={user.id}
            isSelf={user.id === info.id}
            title={
              user
                ? `${user.last_name} ${user.first_name} ${user.middle_name}`
                : 'Пользователь не указан'
            }
          />
        ))}
    </div>
  );
};

export default RoleContainer;
