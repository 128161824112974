export const searchFilters = {
  all: {
    id: 0,
    value: 'all',
    text: 'Все',
    exclude: [],
  },
  withVideo: {
    id: 1,
    value: 'withVideo',
    text: 'С видео',
    exclude: [0, 2],
  },
  withoutVideo: {
    id: 2,
    value: 'withoutVideo',
    text: 'Без видео',
    exclude: [0, 1],
  },
  withComments: {
    id: 3,
    value: 'withComments',
    text: 'С комментариями',
    exclude: [0, 4],
  },
  withoutComments: {
    id: 4,
    value: 'withoutComments',
    text: 'Без комментариев',
    exclude: [0, 3],
  },
  withRatings: {
    id: 5,
    value: 'withRatings',
    text: 'С оценками',
    exclude: [0, 6],
  },
  withoutRatings: {
    id: 6,
    value: 'withoutRatings',
    text: 'Без оценок',
    exclude: [0, 5],
  },
};
