import React from 'react';
import Button from '@material-ui/core/Button';

import {useStyles} from '../styles';

const ExerciseCriterionText = ({
  description,
  onClick,
  isNotRoundedContentRight,
  isNotRoundedContentLeft, 
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          ...(isNotRoundedContentRight && { borderTopRightRadius: 0 }),
          ...(isNotRoundedContentLeft && { borderTopLeftRadius: 0 }),
        }}
        className={classes.criterionTextWrapper}
      >
        {description && (
          <div className={classes.textWrapper}>
            <div className={classes.textItem}>{description}</div>
          </div>
        )}
      </div>
      {onClick && (
        <div className={classes.rateButtonWrapper}>
          <Button className={classes.rateButton} onClick={onClick}>
            Оценить!
          </Button>
        </div>
      )}
    </>
  );
};

export default ExerciseCriterionText;
