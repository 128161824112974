import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import useQuery from '../utils/useQuery'

import {BreadCrumbs, StagePanel, Loader} from '../components'
import {getChamp, getStageList} from '../network/fetchApi'

export default function ChampScreen() {
    const {root, header, tabs} = useStyles();
    const query = useQuery();
    const history = useHistory();

    const [champData, setChampData] = useState({});
    const [stages, setStages] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const onItemClick = (data) => {
        history.push(`/stage?id=${data.id}`)
    }

    const loadUserStages = (offset = 0) => {
        setIsLoading(true)
        getStageList({offset:0,limit:100, filters: [
            {
                field: 'championship_id',
                equal: query.get("id")
            }
        ]}, (responseJson) => {
            setIsLoading(false)
            if(responseJson.status == 200){
                setStages(responseJson.body.items)
            }
        })
    }

    const loadChampByID = (id) => {
        getChamp({id}, (responseJson) => {
            if(responseJson.status == 200){
                setChampData(responseJson.body)
            }
        })
    }

    const filterStages = (initial, data) => {
        const champID = query.get("id");

        const stages = data.reduce((accumulator, currentValue) => 
        accumulator.some(item => item.id === currentValue.stage.id)?accumulator:[...accumulator, currentValue.stage], initial)
        .filter(item => item.championship_id  === champID);

        console.log("stages: "+JSON.stringify(stages))

        return stages
    }

    const isStarted = (time) => {
        const todayDate = new Date()
        const startDate = new Date(time)
        console.log("todayDate: " + todayDate)
        console.log("startDate: " + startDate)
        console.log("isStarted: " + startDate <= todayDate )
        return startDate <= todayDate 
    }

    useEffect(() => {
        const champID = query.get("id");
        loadUserStages()
        loadChampByID(champID)
    }, [])

    return (
        <div className={root}>
            <Grid container direction='column' style={{padding: 20}}>
                <Grid item xs>
                    <BreadCrumbs data={[{name: "Компании", href: '/profile'}, {name: "Чемпионаты", href: champData.company_id?`/company?id=${champData.company_id}`:null}, {name: "Этапы"}]}/>
                </Grid>
                {!isLoading && <Grid item xs direction='column'>
                        {stages.map((item) => (
                            <Grid item xs={12}>
                                {isStarted(item.time_start) && <StagePanel data={item} onClick={onItemClick}/>}
                            </Grid>
                        ))}
                        {stages.length == 0 && 
                            <Typography variant="h6" className={header}>
                                Вы не участвуете ни в одном этапе
                            </Typography>
                        }
                </Grid>}
                {isLoading && <Loader isLoading={isLoading}/>}
             </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    header: {
        width: '100%',
        textAlign: 'left',
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
}));
