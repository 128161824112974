import BaseAPI from './BaseAPI';

class BattleAPI extends BaseAPI {
  static path = '/battle';

  static getBattleFull(id, params) {
    const queryParams = params ? '?filter=' + JSON.stringify(params) : '';

    return BattleAPI.call(`${BattleAPI.path}/${id}/full${queryParams}`, {
      method: 'GET',
    });
  }

  static addBattleVideo(data) {
    return BattleAPI.call(`${BattleAPI.path}/${data.id}/video`, {
      method: 'POST',
      body: data,
    });
  }

  static addBattleComment(data) {
    return BattleAPI.call(`${BattleAPI.path}/${data.id}/comment`, {
      method: 'POST',
      body: data,
    });
  }

  static rateBattleByCriterion(id, data) {
    return BattleAPI.call(`${BattleAPI.path}/${id}/grade`, {
      method: 'POST',
      body: data,
    });
  }
}

export default BattleAPI;
