import React, {useState} from 'react';

import SymbolCircle from '../../../../components/SymbolCircle';
import { useStyles } from '../styles';

const ExerciseGrades = ({ gradesData, selectedGrade, selectedNowGrade, setSelectedNowGrade }) => {
  const classes = useStyles();

  return (
    <div>
      <h4>Оценки</h4>
      <div className={classes.gradesWrapper}>
        {gradesData.map((gradeData) => (
          <div key={gradeData.id} className={classes.gradeItemWrapper}>
            <SymbolCircle
              size={24}
              symbol={gradeData.grade}
              selected={selectedGrade === gradeData.grade}
              selectedNow={selectedNowGrade === gradeData.grade}
              onClick={() => setSelectedNowGrade(gradeData.grade)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExerciseGrades;
