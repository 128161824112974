import { createSlice } from '@reduxjs/toolkit'
// Slice

const slice = createSlice({
  name: 'user',
  initialState: {
    info: null,
    comments: []
  },
  reducers: {
    setUser: (state, action) => {
      state.info = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload
    },
    addComment: (state, action) => {
        let sorted = []
        if(state.comments){
          const sortByKey = key => (a, b) => a[key] >= b[key] ? 1 : -1
          sorted = [...state.comments, action.payload].slice().sort(sortByKey('time'))
        }
        state.comments = sorted
    },
    removeComment: (state, action) => {
        let index = state.comments.findIndex(item => item.id == action.payload.id)
        return {
            ...state,
            comments: [
                ...state.comments.slice(0, index),
                ...state.comments.slice(index + 1)
            ]
        }
    }
  },
});

export default slice.reducer

// Actions
const { setUser, setComments, addComment, removeComment } = slice.actions

export const setUserInfo = (payload) => async dispatch => {
  try {
    dispatch(setUser(payload));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setUserComments = (payload) => async dispatch => {
  try {
    dispatch(setComments(payload));
  } catch (e) {
    return console.error(e.message);
  }
}

export const addUserComment = (payload) => async dispatch => {
try {
  dispatch(addComment(payload));
} catch (e) {
  return console.error(e.message);
}
}

export const removeUserComment = (payload) => async dispatch => {
  try {
    dispatch(removeComment(payload));
  } catch (e) {
    return console.error(e.message);
  }
}