import { Button } from '@material-ui/core';
import React from "react";
import SymbolCircle from "../../../components/SymbolCircle";

import { useStyles } from '../styles';

const  GradesPanel = ({ battleGradesByCriterion, openGradesViewOnlyModal }) => {
    const {
        ratesContainer,
        detailsButton,
        ratesGroup,
        gradeHoverBlock,
        circleHover,
    } = useStyles();
    
    return (
      battleGradesByCriterion.length !== 0 && (
        <>
          <h2 style={{ marginTop: 12 }}>Оценки</h2>
          <div className={ratesContainer}>
            <div className={ratesGroup}>
              {battleGradesByCriterion.map(({ index, criterion_name, average_grade }) => (
                <div key={index} style={{ position: 'relative' }}>
                  <SymbolCircle
                    size={40}
                    selected={false}
                    symbol={average_grade}
                    className={circleHover}
                  />
                  <div className={gradeHoverBlock}>{criterion_name}</div>
                </div>
              ))}
            </div>
            <Button onClick={openGradesViewOnlyModal} className={detailsButton}>
              УЗНАТЬ ПОДРОБНЕЕ
            </Button>
          </div>
        </>
      )
    );
  }
  
  export default GradesPanel;