import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import {getUserInfoByID} from '../network/fetchApi'

export default function CommentItem(props){
    const {data, onClick, onEdit, editable} = props;
    const {user_id, text, time, created_at} = data;
    const {root} = useStyles();

    const [userData, setUserData] = useState(null);

    const getTime = (value) => {
        var date = new Date(0);
        date.setSeconds(value); // specify value for SECONDS here
        return date.toISOString().substr(14, 5);
    }

    useEffect(() => {
        getUserInfoByID({id: user_id}, (responseJson) => {
            if(responseJson.status == 200) {
                setUserData(responseJson.body)
            }
        })
    }, [data])

    return (
        <ListItem alignItems="flex-start">
            <Paper variant="outlined" onClick={() => onClick(time)} style={{width: '100%', padding: 10, cursor: 'pointer'}}>
                <Typography variant='h6' style={{marginBottom: 10, marginRight: 55, wordBreak:'break-word'}}>{userData?`${userData.last_name} ${userData.first_name} ${userData.middle_name}`:''}</Typography>
                <span style={{wordBreak:'break-word', whiteSpace: 'break-spaces'}}>{text}</span>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                    <div style={{color: 'rgba(0,0,0,0.5)'}}>
                        {getTime(time)}
                    </div>
                    <div style={{color: 'rgba(0,0,0,0.5)'}}>
                        {new Date(created_at).toLocaleDateString()}
                    </div>
                </div>
            </Paper>
            {editable && <IconButton
                aria-label="toggle password visibility"
                style={{position: 'absolute', top: 10, right: 20}}
                onClick={() => onEdit({...data, last_name: userData.last_name, first_name: userData.first_name, middle_name: userData.middle_name})}
            >
                <EditIcon/>
            </IconButton>}
        </ListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    }
}));
