import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {LoginScreen, ProfileScreen, BattleScreen, ConfirmScreen, CompanyScreen, ChampScreen,
  StageScreen} from './screens'
import {AppBarMenu} from './components'

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginScreen}/>
          <Route exact path="/confirm" component={ConfirmScreen}/>
          <AppBarMenu>
            <Route path="/profile" component={ProfileScreen}/>
            <Route path="/company" component={CompanyScreen}/>
            <Route path="/champ" component={ChampScreen}/>
            <Route path="/battle" component={BattleScreen}/>
            <Route path="/stage" component={StageScreen}/>
          </AppBarMenu>
        </Switch>
     </Router>
  );
}

export default App;
