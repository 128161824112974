import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {confirmUser} from '../network/fetchApi'

import {useHistory} from "react-router-dom";
import useQuery from '../utils/useQuery'
import {Loader} from '../components'

export default function ConfirmScreen() {
    const {root, header} = useStyles();

    const query = useQuery();
    let history = useHistory();

    const loadConfirm = (email_code) => {
        confirmUser({email_code}, (responseJson) => {
            if(responseJson.status == 200) {
                history.push('/')
            }
        })
    }


    useEffect(() => {
        const confirmKey = query.get("key");
        loadConfirm(confirmKey)
    }, []);

    return (
        <div className={root}>
            <Loader isLoading={true}/>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    }
}));
