import React, { useState, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { useResize } from '../../../../utils/hooks/useResize';
import { useMount } from '../../../../utils/hooks/useMount';

const ExpandablePanel = ({
  open,
  id,
  header,
  Subheader,
  Content,
  toggle,
  onClose = () => {},
  isGradeGiven
}) => {
  const [isNotRoundedContentLeft, setNotRoundedContentLeft] = useState(false);
  const [isNotRoundedContentRight, setNotRoundedContentRight] = useState(false);
  const [isOpenWithAnimation, setOpenWithAnimation] = useState(false);
  const [shadowBlockLeft, setShadowBlockLeft] = useState(0);
  const [expandableHeaderWidth, setExpandableHeaderWidth] = useState(0);
  const [expandableHeight, setExpandableHeight] = useState(0);
  
  const classes = useStyles();

  const size = useResize();
  const isMounted = useMount();

  const wrapperRef = useRef();
  const headerRef = useRef();
  const subheaderRef = useRef();
  const contentRef = useRef();

  const handleHeaderClick = () => {
    if (open) toggle(-1);
    else {
      setOpenWithAnimation(true);
      toggle(id);
    }
  };

  const handleTransitionEnd = () => {
    if (!open) {
      calculateExpandableDimensions(open);
      setOpenWithAnimation(false);
      onClose();
    }
  };

  const calculateExpandableDimensions = useCallback((open) => {
    let resultNotRoundedCornerLeft = false;
    let resultNotRoundedCornerRight = false;
    let resultShadowBlockLeft = 0;
    let resultExpandableHeight = 0;
    let resultExpandableHeaderWidth = 0;

    if (!(wrapperRef.current || headerRef.current)) return;

    const wrapperComputedStyles = window.getComputedStyle(
      wrapperRef.current,
      null,
    );
    const wrapperPaddingTop = parseFloat(wrapperComputedStyles.paddingTop);
    const wrapperPaddingBottom = parseFloat(
      wrapperComputedStyles.paddingBottom,
    );
    const headerComputedStyles = window.getComputedStyle(
      headerRef.current,
      null,
    );
    const headerWidth = parseFloat(headerComputedStyles.width);
    const headerHeight = parseFloat(headerComputedStyles.height);
    const headerBorderTop = parseFloat(headerComputedStyles.borderTopWidth);
    const headerBorderBottom = parseFloat(
      headerComputedStyles.borderBottomWidth,
    );

    resultExpandableHeight =
      wrapperPaddingTop +
      wrapperPaddingBottom +
      headerHeight +
      headerBorderTop +
      headerBorderBottom;
    resultExpandableHeaderWidth = headerWidth;

    if (subheaderRef.current) {
      const subheaderComputedStyles = window.getComputedStyle(
        subheaderRef.current,
        null,
      );
      const subheaderWidth = parseFloat(subheaderComputedStyles.width);
      const subheaderHeight = parseFloat(subheaderComputedStyles.height);

      if (open) resultExpandableHeight += subheaderHeight;
      if (subheaderWidth > resultExpandableHeaderWidth)
        resultExpandableHeaderWidth = subheaderWidth;
    }

    if (contentRef.current) {
      const contentComputedStyles = window.getComputedStyle(
        contentRef.current,
        null,
      );
      const contentWidth = parseFloat(contentComputedStyles.width);
      const contentHeight = parseFloat(contentComputedStyles.height);
      const contentBorderWidth = parseFloat(
        contentComputedStyles.borderLeftWidth,
      );
      const { left: headerLeft } = headerRef.current.getBoundingClientRect();
      const { left: contentLeft } = contentRef.current.getBoundingClientRect();

      if (open) resultExpandableHeight += contentHeight;
      const shadowBlockWidth = headerLeft - contentLeft;
      resultShadowBlockLeft = shadowBlockWidth - contentBorderWidth;

      resultNotRoundedCornerLeft = shadowBlockWidth === 0;
      resultNotRoundedCornerRight =
        contentWidth - resultExpandableHeaderWidth === shadowBlockWidth;
    }

    setNotRoundedContentLeft(resultNotRoundedCornerLeft);
    setNotRoundedContentRight(resultNotRoundedCornerRight);
    setShadowBlockLeft(resultShadowBlockLeft);
    setExpandableHeight(resultExpandableHeight);
    setExpandableHeaderWidth(resultExpandableHeaderWidth);
  }, []);

  useEffect(() => {
    if (open || isMounted()) calculateExpandableDimensions(open);
  }, [
    size,
    open,
    isMounted,
    Content,
    Subheader,
    calculateExpandableDimensions,
  ]);

  return (
    <div
      style={{ height: expandableHeight }}
      className={classes.expandableWrapper}
      onTransitionEnd={handleTransitionEnd}
      ref={wrapperRef}
    >
      <div
        className={classnames({
          [classes.expandableHeader]: true,
          [classes.expandableHeaderOpen]: open,
          [classes.expandableHeaderHighlighted]: isGradeGiven,
        })}
        onClick={handleHeaderClick}
        ref={headerRef}
      >
        <div
          className={classnames({
            [classes.expandableHeaderWrapper]: true,
            [classes.expandableHeaderWrapperOpen]: open,
          })}
        >
          {header}
        </div>
      </div>

      <div
        className={classnames({
          [classes.expandableSubheader]: true,
          [classes.expandableSubheaderOpen]: open,
        })}
        style={{ visibility: isOpenWithAnimation ? 'visible' : 'hidden' }}
        ref={subheaderRef}
      >
        {Subheader}
      </div>

      {Content && (
        <div
          style={{
            ...(isNotRoundedContentRight && { borderTopRightRadius: 0 }),
            ...(isNotRoundedContentLeft && { borderTopLeftRadius: 0 }),
            visibility: isOpenWithAnimation ? 'visible' : 'hidden',
          }}
          className={classnames({
            [classes.expandableContent]: true,
            [classes.expandableContentOpen]: open,
          })}
          ref={contentRef}
        >
          <div
            style={{
              width: expandableHeaderWidth,
              left: shadowBlockLeft,
            }}
            className={classnames({
              [classes.shadowOverlapBlock]: true,
              [classes.shadowOverlapBlockOpen]: isOpenWithAnimation,
            })}
          >
            <div className={classes.shadowOverlapInnerBlock} />
          </div>
          {Content({ isNotRoundedContentRight, isNotRoundedContentLeft })}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  expandableWrapper: {
    fontSize: 16,
    padding: 8,
    lineHeight: '16px',
    overflow: 'hidden',
    transition: 'all 0.5s ease',
  },
  expandableHeader: {
    cursor: 'pointer',
    borderRadius: 12,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    display: 'inline-block',
    transition:
      'border-radius 0.5s ease, border 0.5s ease, box-shadow 0.5s ease, padding 0.5s ease',
    minWidth: 150,
    width: '100%',
    position: 'relative',
    backgroundColor: '#fff',
    padding: 4,
  },
  expandableHeaderHighlighted: {
    boxShadow: '0px 2px 7px green',
  },
  expandableHeaderOpen: {
    padding: '4px 4px 0 4px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: '0px 6px 6px 0px #FCFCFC, 0px 0px 9px rgba(0, 0, 0, 0.2)',
  },
  expandableHeaderWrapper: {
    padding: '6px 8px',
    wordBreak: 'break-all',
    borderRadius: 12,
    userSelect: 'none',
    transition: 'border 0.5s ease',
    border: '4px solid #fcfcfc',
  },
  expandableHeaderWrapperOpen: {
    boxShadow: '0px 7px 0px 0px #fcfcfcfc',
    fontWeight: 'bold',
  },
  expandableSubheader: {
    padding: 12,
    paddingTop: 0,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    transition: 'opacity 0.5s ease',
    opacity: 0,
    zIndex: 1,
    width: '100%',
    position: 'relative',
    border: '4px solid #fff',
    borderTop: 0,
    backgroundColor: '#FCFCFC',
    borderBottom: 0,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  expandableSubheaderOpen: {
    opacity: 1,
    boxShadow:
      '0px -8px 0px -4px #fcfcfc, 3px -7px 0px -3px white, 0px 5px 6px rgb(0 0 0 / 20%)',
  },
  expandableContent: {
    position: 'absolute',
    borderRadius: 12,
    left: 8,
    border: '4px solid #fff',
    width: 'calc(100% - 16px)',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.5s ease',
    opacity: 0,
    zIndex: 1,
    backgroundColor: '#fff',
  },
  expandableContentOpen: {
    opacity: 1,
  },
  shadowOverlapBlock: {
    visibility: 'hidden',
    position: 'absolute',
    width: '100%',
    height: 14,
    top: -14,
    zIndex: 2,
    backgroundColor: '#FCFCFC',
  },
  shadowOverlapBlockOpen: {
    visibility: 'visible',
  },
  shadowOverlapInnerBlock: {
    border: '4px solid #fff',
    borderTop: 0,
    borderBottom: 0,
    height: 14,
  },
});

export default ExpandablePanel;
