import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default function UserProfile(props) {
    const {data, onLogout} = props;
    const {email, first_name, middle_name, last_name, post, team, chief} = data

    const {root, avatar, input} = useStyles();

    return (
            <div elevation='3' className={root}>
                <Grid container direction='column' >
                    <Grid item style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar className={avatar} alt="Remy Sharp"></Avatar>
                    </Grid>
                    <Grid item style={{paddingTop: 30}}>
                        <TextField
                            id="standard-read-only-input"
                            label="Фамилия Имя Отчество"
                            value={`${last_name} ${first_name} ${middle_name}`}
                            className={input}
                            multiline
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="standard-read-only-input"
                            label="Почта"
                            value={email}
                            className={input}
                            multiline
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item style={{paddingTop: 30}}>
                        <TextField
                            id="standard-read-only-input"
                            label="Должность"
                            value={post}
                            className={input}
                            multiline
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="standard-read-only-input"
                            label="Команда"
                            value={team}
                            className={input}
                            multiline
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="standard-read-only-input"
                            label="Руководитель"
                            value={chief}
                            className={input}
                            multiline
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Button onClick={onLogout} variant="contained" color="primary" fullWidth endIcon={<ExitToAppIcon/>} style={{marginTop: 30}}>Выйти</Button>
            </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    avatar: {
        width: 200,
        height: 200,
    },
    input: {
        marginTop: 20
    }
}));
