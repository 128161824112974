import React from 'react';
import Paper from '@material-ui/core/Paper';
import ReactPlayer from 'react-player';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import { LoaderProgress } from '../../../components';

const CheckVideoForm = (props) => {
  const { isLoading, uploadVideoProgress, url, onCancel, onAccept } = props;

  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 500,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
          backgroundColor: isLoading ? 'white' : 'black',
        }}
      >
        {!isLoading && <ReactPlayer width="100%" controls url={url} />}
        <LoaderProgress value={uploadVideoProgress} isLoading={isLoading} />
      </div>
      {!isLoading && (
        <div style={{ display: 'flex', padding: 20 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ flex: 1, marginRight: 20, backgroundColor: 'red' }}
            onClick={onCancel}
            startIcon={<ClearIcon />}
          >
            Отмена
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ flex: 1, backgroundColor: 'green' }}
            onClick={onAccept}
            startIcon={<DoneIcon />}
          >
            Отправить видео
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default CheckVideoForm;
