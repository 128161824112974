import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import {useDispatch, useSelector} from 'react-redux'
import {setUserInfo} from '../redux/user'
import {getUserInfo, userLogOut} from '../network/fetchApi'

import {useHistory, useLocation} from "react-router-dom";

import Logo from '../assets/img/logo.jpg'
import {UserProfile} from '.'

export default function AppBarMenu(props) {
    const {children} = props;
    const {root, header, content} = useStyles();

    const [openDrawer, setOpenDrawer] = useState(false);

    const dispatch = useDispatch()
    const { info } = useSelector(state => state.user)

    let history = useHistory();
    let location = useLocation();

    const loadUserData = () => {
        getUserInfo((responseJson) => {
          if(responseJson.status == 200) {
            dispatch(setUserInfo(responseJson.body))
          }else{
            history.push('/')
          }
        })
      }
  
    const logOut = () => {
        userLogOut((responseJson) => {
          if(responseJson.status == 200) history.push('/')
        })
    }

    const goHome = () => {
        history.push('/profile')
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setOpenDrawer(open);
    };

    useEffect(() => {
        loadUserData();
    }, []);

    if (!info) {
        return null
    }

    return (
        <div className={root}>
            <AppBar position="fixed">
                <Toolbar>
                    <img onClick={goHome} src={Logo} style={{width: 'auto', height: 'auto', maxHeight: 40, marginRight: 10, cursor: 'pointer'}}/>
                    <Typography onClick={goHome} variant="h6" className={header}>
                        Involver 360
                    </Typography>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        onClick={toggleDrawer(true)}
                        color="inherit"
                    >
                        <AccountCircle fontSize="large"/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={content}>
                <Toolbar/>
                {children}
            </div>
            <SwipeableDrawer
                anchor={'right'}
                open={openDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <UserProfile data={info} onLogout={logOut}/>
            </SwipeableDrawer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex'
    },
    header: {
        flexGrow: 1,
        textAlign: 'left',
        cursor: 'pointer'
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    }
}));
