import React from 'react';
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';

import { useStyles } from '../styles';

const RolePanel = (props) => {
  const { title, isSelf } = props;
  const { userHeader } = useStyles();

  return (
    <Paper
      variant="outlined"
      style={{
        display: 'flex',
        borderColor: isSelf ? 'green' : 'primary',
        flexDirection: 'row',
        padding: 10,
        marginBottom: 10,
      }}
    >
      <AccountCircleIcon fontSize="large" />
      <Typography className={userHeader}>{title}</Typography>
    </Paper>
  );
};

export default RolePanel;
