
export const interpolateColors = ({currentRatings, minColor, maxColor, minValue, maxValue}) => 
    currentRatings.map((grade) => {

        const percentage = (grade - minValue) / (maxValue - minValue);
        const red = minColor.red + Math.round(percentage * (maxColor.red - minColor.red));
        const green = minColor.green + Math.round(percentage * (maxColor.green - minColor.green));
        const blue = minColor.blue + Math.round(percentage * (maxColor.blue - minColor.blue));
        const color = `rgb(${red}, ${green}, ${blue})`;
        
        return { grade, color };
               
    });


export default interpolateColors;