import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBottom: 160,
  },
  header: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  },
  userHeader: {
    marginLeft: 5,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    alignSelf: 'center'
  },
  fab1: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: 85
  },
  tabs: {
    marginBottom: theme.spacing(2)
  },
  ratesContainer: {
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
    width: '100%',
    borderRadius: 16,
    padding: "18px 24px 18px 24px",
    marginBottom: 18,
    display: "flex",
    justifyContent: "space-between"
  },
  ratesGroup: {
    display: "flex",
    gap: "12px",
    alignItems: "center"
  },
  gradeHoverBlock: {
    display: "none",
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
    top: 40,
    position: "absolute",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    color: "#828282",
    width: 200,
  },
  circleHover: {
    '&:hover + $gradeHoverBlock': {
      display: 'inline',
    },
  },
  detailsButton: {
    backgroundColor: '#000',
    color: '#fff',
    height: 36,
    borderRadius: 8,
    fontSize: 10, 
    fontWeight: 700,
    padding: "20px 30px 20px 30px",
    marginLeft: 12,
    '&:hover': {
      backgroundColor: '#000'
    }
  },
}));