import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader(props) {
    const {isLoading} = props
    const {root} = useStyles();

    if(!isLoading) return null

    return (
            <div className={root}>
                <CircularProgress />
            </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 100,
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'rgba(255,255,255,0.5)'
    }
}));
