import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

export default function EditCommentDialog(props) {
  const {data, open, onClose, onDelete, onComplete} = props;
  const {last_name, first_name, middle_name, text} = data;

  const [commentText, setCommentText] = useState("");

  const onCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const onCompleteDialog = () => {
    const body = {
      id: data.id,
      text: commentText
    }
    onComplete(body)
  }

  useEffect(() => {
    if(open) setCommentText(text)
  }, [open])

  return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
        <DialogTitle id="form-dialog-title">Изменить комментарий</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='h6' style={{marginBottom: 10, marginRight: 55, wordBreak:'break-word'}}>{`${last_name} ${first_name} ${middle_name}`}</Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Комментарий"
            type="email"
            fullWidth
            multiline
            rowsMax={15}
            variant="outlined"
            placeholder="Введите новый комментарий"
            value={commentText}
            onChange={onCommentChange}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} style={{color: 'red'}}>
            Удалить
          </Button>
          <div style={{flex: 1}}/>
          <Button onClick={onClose} color="primary">
            Отмена
          </Button>
          <Button onClick={onCompleteDialog} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
  );
}
