import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import {setUserInfo} from '../redux/user'
import {useDispatch, useSelector} from 'react-redux'

import {userSignIn, getUserInfo} from '../network/fetchApi'

import {useHistory} from "react-router-dom";

import {Loader} from '../components'

import Logo from '../assets/img/logo.jpg'

export default function LoginScreen() {
  const {root, container, input, avatar} = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useDispatch()

  let history = useHistory();

  const loadUserData = () => {
    setIsLoading(true)
    getUserInfo((responseJson) => {
      setIsLoading(false)
      if(responseJson.status == 200) {
        dispatch(setUserInfo(responseJson.body))
        history.push('/profile')
      }else {
        dispatch(setUserInfo(null))
      }
    })
  }

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value.trim());
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value.trim());
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let errors = {}
    if(email == "") errors.email = true;
    if(password == "") errors.password = true;

    const isOK = Object.keys(errors).length === 0

    if(!isOK) setErrors(errors);
    else setErrors({})

    return isOK
  }

  const onLogin = () => {
    if(validateForm()){
      const body = {
        email,
        password
      }

      setIsLoading(true)
      userSignIn(body, (responseJson) => {
        setIsLoading(false)
        if(responseJson.status == 200){
          loadUserData();
        }else if (responseJson.status == 404){
          setErrors({
            email: true,
            password: true
          });
        }
      })
    }
  }

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className={root}>
        <Paper elevation='3' className={container}>
            <Avatar src={Logo} className={avatar} />
            <TextField
                label="Почта"
                id="standard-start-adornment"
                type={'text'}
                value={email}
                onKeyDown={(e) => {if(e.keyCode == 13) onLogin()}}
                onChange={onEmailChange}
                fullWidth
                className={input}
                error={errors.email}
            />
            <TextField
                label="Пароль"
                id="standard-start-adornment"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onKeyDown={(e) => {if(e.keyCode == 13) onLogin()}}
                onChange={onPasswordChange}
                fullWidth
                className={input}
                error={errors.password}
                helperText={errors.password?"Неверная почта или пароль":""}
                InputProps={{
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={onShowPassword}
                            onMouseDown={onMouseDownPassword}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                }}
            />
            <Button onClick={onLogin} fullWidth variant="contained" color="primary">
                Войти
            </Button>
            <Loader isLoading={isLoading}/>
        </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    },
    container: {
      position: 'relative',
      padding: 20,
      width: '100%',
      maxWidth: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
        width: 150, 
        height: 150
    },
    input: {
        marginBottom: 20
    }
}));
