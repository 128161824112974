import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  criteriaListWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    overflow: 'hidden'
  },
  gradesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -6px',
  },
  gradeItemWrapper: {
    margin: '0 6px',
    border: '2px solid #fff',
    borderRadius: '100%',
  },
  criterionTextWrapper: {
    borderRadius: 12,
    backgroundColor: '#fcfcfc'
  },
  textWrapper: {
    padding: '8px 6px',
    backgroundColor: '#fff',
    whiteSpace: 'pre-wrap',
    borderRadius: 12,
    border: '4px solid #fcfcfc'
  },
  textItem: {
    margin: '16px 4px',
    wordBreak: 'break-all',

    '&:first-child': {
      marginTop: 0,
    },

    '&:last-child': {
      marginBottom: 0
    }
  },
  rateButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff'
  },
  rateButton: {
    backgroundColor: '#000',
    color: '#fff',
    height: 26,
    margin: '6px',
    width: '50%',
    textTransform: 'none',
    alignSelf: 'flex-end',

    '&:hover': {
      backgroundColor: '#000'
    }
  },
});
