import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';

import {
  CommentItem,
  ConfirmActionDialog,
  EditCommentDialog,
} from '../../../components';
import {
  deleteComment,
  updateComment,
} from '../../../network/fetchApi';
import BattleAPI from '../../../network/BattleAPI';

const CommentVideoForm = (props) => {
  const { battleData, setBattleData, rights } = props;
  var player = useRef(null);

  const { info } = useSelector((state) => state.user);

  const [openEditComment, setOpenEditComment] = useState(false);
  const [openConfirmCommentDialog, setOpenConfirmCommentDialog] =
    useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [playedSeconds, setCurTime] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [commentEditData, setCommentEditData] = useState({});

  const onCommentSend = async () => {
    if (commentText != '') {
      const body = {
        id: battleData.battle.id,
        text: commentText,
        time: Math.floor(playedSeconds),
      };

      try {
        const response = await BattleAPI.addBattleComment(body);
        if (response.status === 200) {
          playVideo();
          setCommentText('');
          setBattleData({
            ...battleData,
            video: {
              ...battleData?.video,
              comments: {
                items: [...battleData.video?.comments.items, response.body],
              },
            },
          });
        }
      } catch (error) {}
    }
  };

  const onCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const onEditCommentComplete = (data) => {
    updateComment(data, (responseJson) => {
      if (responseJson.status == 200) {
        onEditCommentClose();
        setBattleData({
          ...battleData,
          video: {
            ...battleData.video,
            comments: {
              items: [
                ...battleData.video?.comments.items.map((item) =>
                  item.id == responseJson.body.id ? responseJson.body : item,
                ),
              ],
            },
          },
        });
      }
    });
  };

  const onDeleteComment = () => {
    deleteComment(commentEditData, (responseJson) => {
      if (responseJson.status == 200) {
        onConfirmCommentDialogClose();
        onEditCommentClose();
        if (
          battleData &&
          battleData.video &&
          battleData.video?.comments &&
          battleData.video?.comments.items
        ) {
          setBattleData({
            ...battleData,
            video: {
              ...battleData.video,
              comments: {
                items: battleData.video?.comments.items.filter(
                  (item) => item.id !== commentEditData.id,
                ),
              },
            },
          });
        }
      }
    });
  };

  const getTime = (value) => {
    var date = new Date(0);
    date.setSeconds(value);
    return date.toISOString().substr(11, 8);
  };

  const handleProgress = (status) => {
    const { playedSeconds } = status;
    setCurTime(playedSeconds);
  };

  const stopVideo = () => {
    setIsPlaying(false);
  };

  const playVideo = () => {
    setIsPlaying(true);
  };

  const onVideoReady = () => {
    setIsReady(true);
  };

  const seekVideoTo = (time) => {
    player.current.seekTo(time, 'seconds');
  };

  const onEditCommentOpen = (data) => {
    setCommentEditData(data);
    setOpenEditComment(true);
  };

  const onEditCommentClose = () => {
    setOpenEditComment(false);
  };

  const onConfirmCommentDialogClose = () => {
    setOpenConfirmCommentDialog(false);
  };

  const onConfirmommentDialogOpen = () => {
    setOpenConfirmCommentDialog(true);
  };

  if (battleData == null && battleData.video == null) return null;

  return (
    <Paper elevation={3}>
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          md={8}
          style={{ padding: 20, backgroundColor: 'black' }}
        >
          {rights.watch && (
            <ReactPlayer
              height={isReady ? '100%' : 0}
              width={isReady ? '100%' : 0}
              playing={isPlaying}
              onProgress={handleProgress}
              onPlay={playVideo}
              onReady={onVideoReady}
              ref={player}
              controls
              url={battleData.video?.link}
            />
          )}
          {!rights.watch && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ color: 'white' }}>
                Для просмотра видео обратитесь к администратору
              </span>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ height: 500, display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              flexGrow: 1,
              overflow: 'auto',
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            {battleData.video?.comments &&
              battleData.video?.comments.items.sort((a, b) => a.time - b.time).map((comment) => {
                if (rights.comments == 'self' && comment.user_id == info.id) {
                  return (
                    <CommentItem
                      editable={rights.write}
                      data={comment}
                      onClick={seekVideoTo}
                      onEdit={onEditCommentOpen}
                    />
                  );
                } else if (rights.comments == 'all') {
                  return (
                    <CommentItem
                      editable={rights.write}
                      data={comment}
                      onClick={seekVideoTo}
                      onEdit={onEditCommentOpen}
                    />
                  );
                }
              })}
            {(!battleData.video?.comments ||
              battleData.video?.comments.items.length === 0) && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Нет комментариев</span>
              </div>
            )}
          </div>
          {rights.write && (
            <div style={{ padding: 10 }}>
              <div>{`Время: ${getTime(playedSeconds)}`}</div>
              <TextField
                id="standard-full-width"
                label="Комментарий"
                multiline
                rowsMax={15}
                variant="outlined"
                value={commentText}
                style={{ width: '100%' }}
                placeholder="Введите комментарий"
                margin="normal"
                onFocus={stopVideo}
                onChange={onCommentChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                      variant="filled"
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onCommentSend}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <EditCommentDialog
        data={commentEditData}
        onDelete={onConfirmommentDialogOpen}
        open={openEditComment}
        onClose={onEditCommentClose}
        onComplete={onEditCommentComplete}
      />
      <ConfirmActionDialog
        title="Удалить коментарий?"
        text="Вы уверены, что хотите удалить комментарий?"
        open={openConfirmCommentDialog}
        onClose={onConfirmCommentDialogClose}
        onConfirm={onDeleteComment}
      />
    </Paper>
  );
};

export default CommentVideoForm;
