import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { BreadCrumbs, CompanyPanel, Loader } from '../components';
import { getCompanyList } from '../network/fetchApi';

export default function ProfileScreen() {
    const {root, header, tabs} = useStyles();
    let history = useHistory();

    const [companies, setCompanies] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const onItemClick = (data) => {
        history.push(`/company?id=${data.id}`)
    }

    const loadUserCompanies = async (offset = 0) => {
      setIsLoading(true);
      let responseJson;
  
      do {
        responseJson = await fetchUserCompanies(offset);
        
        if (responseJson.status === 200) {
          let newData = responseJson.body
          setCompanies(newData.items)
          offset += responseJson.body.items.length;
        } else {
          break;
        }
      } while (offset < responseJson.body.hits);
      setIsLoading(false);
    };
  
    const fetchUserCompanies = async (offset) => {
      return new Promise((resolve, reject) => {
        getCompanyList({ offset, limit: 100 }, (responseJson) => {
          resolve(responseJson);
        });
      });
    };

    useEffect(() => {
        loadUserCompanies()
    }, [])

    return (
        <div className={root}>
            <Grid container direction='column' style={{padding: 20,}}>
                <Grid item xs>
                    <BreadCrumbs data={[{name: "Компании"}]}/>
                </Grid>
                {!isLoading && <Grid item xs direction='column'>
                    {companies.map((item) => (
                        <Grid item xs={12}>
                            <CompanyPanel data={item} onClick={onItemClick}/>
                        </Grid>
                    ))}
                    {companies.length == 0 && 
                        <Typography variant="h6" className={header}>
                                Вы не участвуете ни в одном упражнении
                        </Typography>
                    }
                </Grid>}
                {isLoading && <Loader isLoading={isLoading}/>}
             </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    header: {
        width: '100%',
        textAlign: 'left',
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
}));
