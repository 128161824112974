const protocolHttps = "https://"
const protocolHttp = "http://"
const postfix = "/api"

export const APIHOST = process.env.NODE_ENV === 'production' ? protocolHttps + process.env.REACT_APP_HOST + postfix :  postfix

export const USERSIGNIN = APIHOST + '/signin'
export const USERLOGOUT = APIHOST + '/logout'
export const USERGET = APIHOST + '/user'
export const GETBATTLE = APIHOST + '/battle'
export const BATTLELIST = APIHOST + '/battles'
export const VIMEOCREATE = 'https://api.vimeo.com/me/videos'
export const GETFILE = protocolHttps + process.env.REACT_APP_HOST
export const CONFIRMUSER = APIHOST + '/confirm'
export const GETCOMMENT = APIHOST + '/comment'
export const GETCHAMP = APIHOST + '/championship'
export const GETSTAGE = APIHOST + '/stage'

export const COMPANYLIST = APIHOST + '/companies'
export const CHAMPLIST = APIHOST + '/championships'
export const STAGELIST = APIHOST + '/stages'
export const ROLES = '/roles'