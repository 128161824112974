import React, { useState, useCallback, useMemo } from 'react';

import ExerciseCriterion from './ExerciseCriterion';
import { useStyles } from './styles';
import { Loader } from '../../../components';

const ExerciseAssessment = ({
  battleId,
  battleGrades,
  criteria,
  isRatingInProgress,
  onRateByCriterion,
}) => {
  const [openedExpandableId, setOpenedExpandableId] = useState(-1);

  const classes = useStyles();

  const battleGradesByCriterion = useMemo(
    () =>
      criteria.reduce((acc, criterion) => {
        return {
          ...acc,
          [criterion.id]: battleGrades.find(
            (battleGrade) => battleGrade.criterion_id === criterion.id,
          ),
        };
      }, {}),
    [battleGrades, criteria],
  );

  const toggleExpandable = useCallback((id) => {
    setOpenedExpandableId(id);
  }, []);

  return (
    <div
      className={classes.criteriaListWrapper}
    >
      <Loader isLoading={isRatingInProgress} />
      {criteria.map((criterion) => (
        <ExerciseCriterion
          key={criterion.id}
          battleId={battleId}
          criterion={criterion}
          battleGrade={battleGradesByCriterion[criterion.id]}
          openedExpandableId={openedExpandableId}
          toggleExpandable={toggleExpandable}
          onRateByCriterion={onRateByCriterion}
        />
      ))}
    </div>
  );
};

export default ExerciseAssessment;
