import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import 'date-fns';
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';

export default function ChampPanel(props) {
    const {data, onClick} = props;
    const {title, time_start, time_finish} = data;
    const {root, header, helpText} = useStyles();

    return (
        <Paper elevation={3} className={root} onClick={() => onClick(data)}>
            <Typography variant="h4" align='left' style={{width: '100%' , marginBottom: 20}}>Чемпионат</Typography>
            <TextField
                id="standard-read-only-input"
                label="Название"
                value={title}
                variant="outlined"
                className={header}
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
                <div className={header}>
                    <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{width: '100%'}}
                        id="date-picker-inline"
                        label="Дата начала"
                        value={time_start}
                        inputVariant="outlined"
                        TextFieldComponent={TextFieldComponent}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{width: '100%'}}
                        id="date-picker-inline"
                        label="Дата конца"
                        value={time_finish}
                        inputVariant="outlined"
                        TextFieldComponent={TextFieldComponent}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <span className={helpText}>Нажмите, чтобы открыть этапы</span>
        </Paper>
    );
}

const TextFieldComponent = (props) => {
    return <TextField 
            {...props} 
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <EventIcon color={'inherit'}/>
                    </InputAdornment>
                ),
                readOnly: true
            }}
        />
  }

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        padding: 30,
        paddingBottom: 15,
        marginBottom: 30,
        cursor: 'pointer'
    },
    header: {
        width: '100%',
        pointerEvents: 'none'
    },
    helpText: {
        marginTop: 15,
        fontSize: 12,
        opacity: 0.5,
    }
}));
